<template>
  <div>
    <app-back-header></app-back-header>
    <v-row no-gutters dense class="my-card d-flex mb-8 flex-column">
      <div class="pa-0">
        <v-row no-gutters dense class="d-flex flex-grow-0">
          <v-col cols="5" class="pa-0">
            <p class="my-heading">
              Bids
              <span class="heading-sf16">of {{ titleName }}</span>
            </p>
          </v-col>
          <v-col cols="6" class="pa-0 filter-search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              dense
              color="#CACEDA"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="export-icon">
            <div v-on:click="bidExport()" style="cursor: pointer">
              <v-tooltip left color="#757575">
                <template v-slot:activator="{ on }">
                  <img
                    v-on="on"
                    :src="$store.state.icons.icons['file-export']"
                    style="
                      height: 24px;
                      filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%);
                    "
                    alt
                  />
                </template>
                <span>Export bid detail</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :items="listData"
        :page.sync="page"
        :headers="header"
        :items-per-page="itemsPerPage"
        :search="search"
        hide-default-footer
        mobile-breakpoint="1080"
        no-data-text="No record found"
      >
        <template v-slot:item.status="{ item }">
          <div
            v-if="item.status === 'AC'"
            class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
          >
            Active
          </div>
          <div
            v-else-if="item.status === 'A'"
            class="text-tag-green text-tag-btn d-flex align-center justify-center my-2"
          >
            Accepted
          </div>
          <div
            v-else-if="item.status === 'E'"
            class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
          >
            Expired
          </div>
          <div
            v-else-if="item.status === 'R'"
            class="text-tag-red text-tag-btn d-flex align-center justify-center my-2"
          >
            Rejected
          </div>
          <div
            v-else-if="item.status === 'LB'"
            class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
          >
            Lost Bid
          </div>
        </template>
        <template v-slot:item.sub_total="{ item }">
          <div>{{ $options.filters.currency(item.sub_total) }}</div>
        </template>
        <template v-slot:item.shipping="{ item }">
          <div>{{ $options.filters.currency(item.shipping) }}</div>
        </template>
        <template v-slot:item.service_fee="{ item }">
          <div>
            {{ $options.filters.currency(item.service_fee) }}
            {{ "(" + 5 + "%)" }}
          </div>
        </template>
        <template v-slot:item.sales_tax="{ item }">
          <div>
            {{ $options.filters.currency(item.sales_tax) }}
            {{ "(" + item.tax_percentage + "%)" }}
          </div>
        </template>
        <template v-slot:item.total_amount="{ item }">
          <div>{{ $options.filters.currency(item.total_amount) }}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip left color="#757575">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="my-pointer" v-on:click="bidView(item)">
                <img
                  :src="$store.state.icons.icons['detail-view']"
                  style="height: 26px"
                  class="table-icon"
                />
              </div>
            </template>
            <span>Bid detail</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <div class="d-flex align-center justify-center my-tabel-footer py-2">
        <v-pagination
          v-model="page"
          color="#2974FF"
          :total-visible="7"
          :length="getTotalPage(this.$store.state.bid.bid_data)"
        ></v-pagination>
      </div>
    </v-row>
    <app-bid-view ref="bidview"></app-bid-view>
  </div>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
import { listDetails } from "../../services/listservice";
import { bidExport } from "../../services/bidservice";
export default {
  components: {
    AppBidView: () => import("../common/BidView"),
    AppBackHeader: () => import("./Header"),
  },

  data() {
    return {
		export_title: '',
      page: 1,
      itemsPerPage: 10,
      name: "",
      search: "",
      bid_data: [],
      header: [
        { text: "Company Name", value: "company_name" },
        { text: "Distance (miles)", value: "distance" },
        { text: "Sub Total", value: "sub_total" },
        { text: "Shipping", value: "shipping" },
        { text: "Service Fee", value: "service_fee" },
        { text: "Sales Tax", value: "sales_tax" },
        { text: "Total Amount", value: "total_amount" },
        { text: "Status", value: "status" },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  computed: {
    listData() {
      let data = this.$store.getters.getBid;
      if (this.$router.currentRoute.matched[1].path === "/contractors") {
        if (data.length === 0) {
          let id = null;
          id = parseInt(this.$route.params.id);
          if (id !== null) {
            this.$store.dispatch("listBids", id);
            data = this.$store.getters.getList;
          }
        }
      } else if (
        this.$router.currentRoute.matched[1].path === "/distributors"
      ) {
        if (data.length === 0) {
          let id = null;
          id = parseInt(this.$route.params.id);
          if (id !== null) {
            this.$store.dispatch("companyBids", id);
            data = this.$store.getters.getList;
          }
        }
      }
      return data;
    },

    titleName() {
      let id = null;
      id = parseInt(this.$route.params.id);
      if (this.$router.currentRoute.matched[1].path === "/contractors") {
		    this.export_title = "Contractor bid"
        if (id !== null) {
          let data = JSON.parse(
            JSON.stringify(this.$store.getters.getListById(id))
          );
          if (data === true) {
            this.getListName(id);
          }
          this.name = data.list_name;
        }
      } else if (
        this.$router.currentRoute.matched[1].path === "/distributors"
      ) {
		    this.export_title = "Distributor bid"
        if (id !== null) {
          let data = JSON.parse(
            JSON.stringify(this.$store.getters.getDistributorByCompanyId(id))
          );
          if (data === true) {
            this.$store.dispatch("distList");
            data = JSON.parse(
              JSON.stringify(this.$store.getters.getDistributorByCompanyId(id))
            );
          }
          this.name = data.fullname;
        }
      }

      return this.name;
    },
  },

  methods: {
    async getListName(id) {
      let data = await listDetails(id);
      this.name = data.data.list_name;
      this.$store.dispatch("listOfCont", data.data.contractor_id);
    },

    getTotalPage(data) {
      return Math.ceil(data.length / this.itemsPerPage);
    },

    bidView(item) {
      this.$refs.bidview.openBidView(item.bid_id);
    },

    bidExport() {
      this.bid_data = this.$store.getters.getBid;
      const options = {
        filename: this.export_title,
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: this.export_title,
        useTextFile: false,
        useBom: true,
        // useKeysAsHeaders: true,
        headers: [
          "Company_Name",
          "Distance(miles)",
          "Sub_Total",
          "Shipping",
          "Service_Fee",
          "Sales_Tax",
          "Total_Amount",
          "Status",
        ],
      };

      const csvExporter = new ExportToCsv(options);

      let bid_ob = [];
      this.bid_data.forEach((data) => {
        let bid = {};
        bid.company_name = data.company_name;
        bid.distance = data.distance;
        bid.sub_total = data.sub_total;
        bid.shipping = data.shipping;
        bid.service_fee = data.service_fee;
        bid.sales_tax = data.sales_tax;
        bid.total_amount = data.total_amount;
        if (data.status === "AC") {
          bid.status = "Active";
        } else if (data.status === "E") {
          bid.status = "Expired";
        } else if (data.status === "A") {
          bid.status = "Accepted";
        }else if (data.status === "R") {
          bid.status = "Rejected";
        }else if (data.status === "LB") {
          bid.status = "Lost Bid";
        }
        bid_ob.push(bid);
      });
      csvExporter;
      csvExporter.generateCsv(bid_ob);
    },
  }
};
</script>
